import React , { useContext, useState,useEffect } from 'react'
import burger from '../imgs/burger_8648590.png';
import OrdersList from './Buttons/OrdersList';
import { OrdersListContext } from '../Contexts/OrdersContext';
import axios from 'axios';


export default function MenusList() {

    const {updateTotal,removeOrderItem,addOrder,pickupPercent}=useContext(OrdersListContext);
    //menu from API
    const [menus,setMenus] = useState([])
    const [filter, setfilter] = useState([]);
    const [menuCategories,setMenuCategories]=useState([])
    useEffect(() => {
        axios.get('https://www.primustechsolutions.online/api/v1/foodmenu')
        .then(function (response) {
            setMenus( response.data)
            setfilter(response.data)
        })
        .catch(function (error) {
          // console.log(error)
        })

        //Categories
        axios.get('https://www.primustechsolutions.online/api/v1/category')
        .then(function (response) {
            setMenuCategories( response.data)
        })
        .catch(function (error) {
           // console.log(error)
        })


    }, []);  
    //filters Code//
    const selectedfilter=(menu)=>{
           menu==='All'?setfilter(menus):setfilter(menus.filter((menuItem)=>menuItem.categoryId===menu))
    }
    //End Filters Code
  return (
    <div className='mg-t-1'>
             <div className="header ht1">
        <h1>
          Our Menu
        </h1>
        <p>
        Making every bite a symphony of flavors
        </p>
        <hr/>
      </div>
      
          
          <div className='filter-container'>
     
                <h5>Filters</h5>
            <div className='filter-buttons'>
                <button onClick={()=>selectedfilter('All')}>
                    All
                </button>

                {
                    menuCategories.length!==0?
                    menuCategories.map((category,index)=>{
                        return(  
                             <button key={index} onClick={()=>selectedfilter(category.id)}>
                        {category.name}
                    </button>
                   )
                     
                       
                    }) :<p></p>
                }
                
            </div>
            <hr />
            </div>
        <div className='menus menu-pg'>
           
        {
            
            
            filter.map((menu,index)=>{ 
    
               return( 
               
                
                      <div className='menu_card-3'  key={index}>
                        <div className={menu.description!==''?'description':'description desc_center'} >
                <div className='menu_img'>
                    <img src={burger} alt='food sItem' />
                </div>
                <div className='menu_desc'>
                  <p className='menu_name'>{menu.name}</p>
                  </div>
                  </div>
                  <div className={menu.description!==''?'option_details':'opt_none'}>
                  <p>{menu.description}</p>
             
                  
                  </div>
                  <div className='buy-container'>  
                       <p >£ <span className='price'>{Number(menu.price).toFixed(2)}</span></p>
                    <p className='default-btn' ><button onClick={()=>addOrder(menu)}>Buy</button></p>

                  </div>

                

              </div>

               
     
                )
            })
        } 
        </div>

        <OrdersList  updateTotal={updateTotal} pickupPercent={pickupPercent} removeOrderItem={removeOrderItem}/>
     
    </div>
  )
}
