import React from 'react'
import { Link } from 'react-router-dom'

export default function Cancel() {
  return (
    <div className='order-status-card'>
    <div className='status-ico failIco '>
    <svg width="256" height="256" viewBox="0 0 256 256" >

<defs>
</defs>
<g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
	<path d="M 24.959 68.04 c -0.768 0 -1.536 -0.293 -2.121 -0.879 c -1.172 -1.171 -1.172 -3.071 0 -4.242 l 40.081 -40.081 c 1.172 -1.172 3.07 -1.172 4.242 0 c 1.172 1.171 1.172 3.071 0 4.242 L 27.081 67.161 C 26.495 67.747 25.727 68.04 24.959 68.04 z" transform=" matrix(1 0 0 1 0 0) " />
	<path d="M 65.04 68.04 c -0.768 0 -1.535 -0.293 -2.121 -0.879 L 22.838 27.081 c -1.172 -1.171 -1.172 -3.071 0 -4.242 c 1.171 -1.172 3.071 -1.172 4.242 0 l 40.081 40.081 c 1.172 1.171 1.172 3.071 0 4.242 C 66.575 67.747 65.808 68.04 65.04 68.04 z"  transform=" matrix(1 0 0 1 0 0) "  />
	<path d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z M 45 6 C 23.495 6 6 23.495 6 45 s 17.495 39 39 39 s 39 -17.495 39 -39 S 66.505 6 45 6 z" transform=" matrix(1 0 0 1 0 0) "  />
</g>
</svg>
 
    </div>
    <div className='status-text'>
      <h5>Order Failed</h5>

    </div>
    <div className='continue-shopping'>
        <Link to="/menu"><button className='fail_btn'>Please Try Again</button></Link>

      </div>

  </div>
  )
}
