import React from 'react'
import Success from './Success'
import Cancel from './Cancel'
import { useParams } from 'react-router-dom'

export default function Order_status() {
  const {status} = useParams()
  const successOrder='b5bea41b6c623f7c09f1bf24dcae58ebab3c0cdd90ad966bc43a45b44867e12b'

  return (
    <div  className='sc_wrapper'>
      {status==='true'?<Success />:<Cancel />} 
    </div>
  )
}
