import React from 'react'

export default function MenuButton() {
  return (
    <div>
        <button  className='book_a_table'>
            Our Menu
        </button>
    </div>
  )
}
