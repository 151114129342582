import React from 'react'
import { Link } from 'react-router-dom'

export default function Success() {
  return (
    
    <div className='order-status-card'>
      <div className='status-ico successIco'>
      <svg width="256" height="256" viewBox="0 0 256 256" x={0} y={0}>

<defs>
</defs>
<g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
	<path d="M 43.077 63.077 c -0.046 0 -0.093 -0.001 -0.14 -0.002 c -1.375 -0.039 -2.672 -0.642 -3.588 -1.666 L 23.195 43.332 c -1.84 -2.059 -1.663 -5.22 0.396 -7.06 c 2.059 -1.841 5.22 -1.664 7.06 0.396 l 12.63 14.133 l 38.184 -38.184 c 1.951 -1.952 5.119 -1.952 7.07 0 c 1.953 1.953 1.953 5.119 0 7.071 L 46.612 61.612 C 45.674 62.552 44.401 63.077 43.077 63.077 z" transform=" matrix(1 0 0 1 0 0) " />
	<path d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 2.762 0 5 2.239 5 5 s -2.238 5 -5 5 c -19.299 0 -35 15.701 -35 35 s 15.701 35 35 35 s 35 -15.701 35 -35 c 0 -2.761 2.238 -5 5 -5 s 5 2.239 5 5 C 90 69.813 69.813 90 45 90 z" transform=" matrix(1 0 0 1 0 0)"/>
</g>
</svg>
      </div>
      <div className='status-text'>
        <h5>Order has been and placed and being processed ...</h5>

      </div>
      <div className='continue-shopping'>
        <Link to='/'><button className='success_btn'>Home >></button></Link>

      </div>

    </div>
    
  )
}
